import { axiosInstance } from './../config';

interface Params {
	requestedDatetimeFrom: Date | string | null;
	requestedDatetimeTo?: Date | string | null;
	authStatusFilters?: string[] | null;
	statusFilters?: string[];
	ticketType?: string[];
	locationIds?: string[];
	sortBy?: string;
	offset: number;
	limit: number;
	idOfferPlan?: string;
	userEmail?: string;
	bookmakerEmail?: string;
	idTicket?: string;
	online?: boolean;
}

export const cashout = {
	getTicketCashouts(params: Params): Promise<any> {
		return axiosInstance.post(`/cashout/items`, params).then(({ data }) => data);
	},

	getTicketCashout(ticketId: string) {
		return axiosInstance(`/cashout/items/${ticketId}`).then((res) => res.data);
	},
	getEventsAdditionalData(eventIds: string[]) {
		return axiosInstance
			.get(`/risk/events`, {
				params: {
					eventIds,
				},
			})
			.then((res) => res.data);
	},

	getMappedMarkets() {
		return axiosInstance.get(`administration/markets/structured/`).then((res) => res.data);
	},
};
