import { basicReport } from './reports/basic-report';
import { cashflow } from './reports/cashflow';
import { currencies } from './administrations/currencies';
import { transactions } from './administrations/transactions';
import { SSO } from './sso/index';
import { sports } from './administrations/sports';
import { categories } from './administrations/categories';
import { tournaments } from './administrations/tournaments';
import { competitors } from './administrations/competitors';
import { players } from './administrations/players';
import { events } from './administrations/events';
import { liveEvents } from './administrations/liveEvents';
import { themes } from './administrations/themes';
import { users } from './administrations/users';
import { markets } from './administrations/markets';
import { translations } from './administrations/translations';
import { mappings } from './pairing/index';
import { integrations } from './integrations/index';
import { bonuses } from './prematch/bonuses';
import { playerProps } from './prematch/playerProps';
import { specialEvents } from './prematch/specialEvents';
import { mixSpecial } from './prematch/mixSpecial';
import { conditions } from './parameters/conditions';
import { limits } from './parameters/limits';
import { authParams } from './parameters/authParams';
import { risk } from './risk';
import { support } from './online-support/users';
import { results } from './results';
import { geo } from './geo';
import offerPlans from './offerPlans/index';
import lists from './lists';
import { cashout } from './cashout';
import { tickets } from './reports/tickets';
import { reservations } from './reports/reservations';
import { verification } from './reports/verification';
import { userBonuses } from './administrations/bonuses';
import { banners } from './administrations/banners';
import { betMedia } from './betMedia';
import { logs } from './administrations/logs';
import { applicationsTypes } from './administrations/applications';
import { vouchers } from './administrations/vouchers';
import { promotions } from './administrations/promotions';
import { regulations } from './administrations/regulations';
import { emailService } from './administrations/emailService';
import { lms } from './pairing/lms';
import { notificationTemplates } from './online-support/templates';
import { notifications } from './online-support/notifications';
import { promotionsReport } from './reports/promotions-report';
import { risk_groups } from './administrations/risk_groups';
import { systemNotifications } from './administrations/systemNotifications';
import { logins } from './online-support/loging';
import { paymentTransactions } from './reports/payment-transactions';
import { ticketsReport } from './reports/tickets-report';
import { affiliate } from './administrations/affiliate';
import { timezones } from './administrations/timezones';
import { transactionsReport } from './reports/transactions';
import { onlineReport } from './reports/online-report';
import { amlReport } from './reports/aml-report';

export default {
	sports,
	categories,
	tournaments,
	competitors,
	players,
	translations,
	transactions,
	events,
	bonuses,
	playerProps,
	specialEvents,
	mixSpecial,
	conditions,
	limits,
	authParams,
	liveEvents,
	markets,
	mappings,
	...offerPlans,
	...lists,
	risk,
	results,
	integrations,
	SSO,
	users,
	geo,
	cashout,
	tickets,
	reservations,
	verification,
	currencies,
	userBonuses,
	support,
	cashflow,
	banners,
	basicReport,
	betMedia,
	logs,
	applicationsTypes,
	vouchers,
	promotions,
	regulations,
	emailService,
	themes,
	lms,
	notificationTemplates,
	notifications,
	promotionsReport,
	risk_groups,
	systemNotifications,
	logins,
	paymentTransactions,
	ticketsReport,
	affiliate,
	timezones,
	transactionsReport,
	onlineReport,
	amlReport,
};
